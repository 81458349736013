<template>
  <modal
    :priority="isTopPriority"
    :destination="destination"
    @forced-nextlink="goNextLink()"
  >
    <template v-slot:body>
      <div class="default-pop" v-if="popType === 'custom'">
        <div class="close" @click="close()">
          <img src="@/assets/images/x.svg" />
        </div>
        <div class="header">
          <h4 v-html="titleMutated"></h4>
        </div>
        <div class="content">
          <p v-html="contentMutated"></p>
        </div>
        <div
          class="btn-wrap-multiple"
          v-if="confirmBtnText !== undefined && cancelBtnText !== undefined"
        >
          <button
            v-text="cancelBtnText"
            type="button"
            class="prev"
            @click="close()"
            v-if="cancelBtnText !== undefined"
          />
          <button
            v-text="confirmBtnText"
            type="button"
            class="activated"
            @click="next()"
            v-if="confirmBtnText !== undefined"
          />
        </div>
        <div class="btn-wrap" v-else>
          <button
            v-text="cancelBtnText"
            type="button"
            class="prev"
            @click="close()"
            v-if="cancelBtnText !== undefined"
          />
          <button
            v-text="confirmBtnText"
            type="button"
            class="activated"
            @click="next()"
            v-if="confirmBtnText !== undefined"
          />
        </div>
      </div>
      <div class="default-pop" v-if="popType === 'help_desk'">
        <div class="close" @click="close()">
          <img src="@/assets/images/x.svg" />
        </div>
        <div class="header">
          <h4 v-html="titleMutated"></h4>
        </div>
        <div class="content">
          <p v-html="contentMutated"></p>
        </div>
        <div
          class="btn-wrap-multiple"
          v-if="confirmBtnText !== undefined && cancelBtnText !== undefined"
        >
          <button
            v-text="cancelBtnText"
            type="button"
            class="prev"
            @click="close()"
            v-if="cancelBtnText !== undefined"
          />
          <button
            v-text="confirmBtnText"
            type="button"
            class="activated"
            @click="next()"
            v-if="confirmBtnText !== undefined"
          />
        </div>
        <div class="btn-wrap" v-else>
          <button
            v-text="cancelBtnText"
            type="button"
            class="prev"
            @click="close()"
            v-if="cancelBtnText !== undefined"
          />
          <button
            v-text="confirmBtnText"
            type="button"
            class="activated"
            @click="next()"
            v-if="confirmBtnText !== undefined"
          />
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
import Modal from "./Modal.vue";

export default {
  props: {
    popupSet: {
      title: {
        type: String,
      },
      content: {
        type: String,
      },
      confirmBtnText: {
        type: String,
      },
      cancelBtnText: {
        type: String,
      },
      popType: {
        type: String,
      },
      nextLink: {
        type: String,
      },
      prevLink: {
        type: String,
      },
      isTopPriority: {
        type: Boolean,
      },
      action: {
        type: String,
      },
      destination: {
        type: Boolean,
      },
      callFunction: {
        type: Boolean,
      },
      dataParam: {
        type: String,
      },
      imgUrl: {
        type: String,
      },
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
      input: "",
      valid: false,
      popType: this.popupSet.popType || "warn",
      title: this.popupSet.title,
      content: this.popupSet.content,
      dataParam: this.popupSet.dataParam,
      confirmBtnText: this.popupSet.confirmBtnText,
      cancelBtnText: this.popupSet.cancelBtnText,
      nextLink: this.popupSet.nextLink,
      prevLink: this.popupSet.prevLink,
      isTopPriority: this.popupSet.isTopPriority,
      action: this.popupSet.action,
      destination: this.popupSet.destination,
      callFunction: this.popupSet.callFunction,
      imgUrl: this.popupSet.imgUrl,
    };
  },
  watch: {},
  mounted() {},
  computed: {
    titleMutated() {
      if (this.title !== undefined && this.title.indexOf("\n") !== -1) {
        return this.title.split("\n").join("<br />");
      } else {
        return this.title;
      }
    },
    contentMutated() {
      if (this.content !== undefined && this.content.indexOf("\n") !== 1) {
        return this.content.split("\n").join("<br />");
      } else {
        return this.content;
      }
    },
  },
  methods: {
    ...mapActions("admin", ["CHANGE_STATUS"]),
    ...mapMutations("basic", ["SET_POPUP"]),
    goNextLink() {
      this.$router.push(this.nextLink).catch(()=>{});
    },
    async next() {
      try {
        const searchOpt = {
          drId: this.dataParam,
        };
        //console.log(searchOpt);
        await this.CHANGE_STATUS(searchOpt).then(() => {
          //console.log("success");
          //console.log("AdminPopNextLink: ", this.nextLink);

          this.SET_POPUP(false);
          if (this.action !== undefined) {
            this.$emit("reload-pop", this.action);
          }
          if (this.nextLink !== undefined) {
            this.$router.push(this.nextLink).catch(()=>{});
          }
          if (this.callFunction) {
            this.$emit("callFunction");
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    prev() {
      this.SET_POPUP(false);
      this.$router.push(this.prevLink).catch(()=>{});
    },
    close() {
      this.SET_POPUP(false);
    },
  },
  created() {
    //console.log("팝업아이템", this.popupSet);
  },
};
</script>

<style lang="scss" scoped></style>
