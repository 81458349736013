<template>
  <div class="img-viewer">
    <img
      :src="src"
      ref="img"
      class="origin"
      @mousedown="isDrag = true"
      @mouseup="isDrag = false"
      @mousemove="moveImage"
      draggable="false"
    /> 
    <!-- <v-app style="width: 300px; height: 300px">
      <v-carousel
        ref="img"
        class="origin"
        @mousedown="isDrag = true"
        @mouseup="isDrag = false"
        @mousemove="moveImage"
        draggable="false"
      >
        <v-carousel-item :src="imgList[0]" @click="viewPhoto(imgList[0])">
        </v-carousel-item>
        <v-carousel-item :src="imgList[1]" @click="viewPhoto(imgList[1])">
        </v-carousel-item>
        <v-carousel-item :src="imgList[2]" @click="viewPhoto(imgList[2])">
        </v-carousel-item>
        <v-carousel-item :src="imgList[3]" @click="viewPhoto(imgList[3])">
        </v-carousel-item>
      </v-carousel>
    </v-app> -->

    <div @click="doClose" style="width: 100%; height: 100%; z-index: 3; " />
  </div>
</template> 
<script>
export default {
  props: {
    imgList: Array,
    src: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isDrag: false,
      startPos: { x: 0, y: 0 },
    };
  },
  mounted() {
    this.resizeImage();
    const img = this.$refs.img;
    img.addEventListener("touchstart", this.startHandler);
    img.addEventListener("touchmove", this.moveHandler);
    img.addEventListener("touchend", this.endHandler);
    img.addEventListener("mousedown", this.startHandler);
    img.addEventListener("mousemove", this.moveHandler);
    img.addEventListener("mouseup", this.endHandler);
    const body = document.querySelector("body");
    img.addEventListener("load", () => {
      img.style.left = `${body.clientWidth / 2 - img.clientWidth / 2}px`;
      img.style.top = `${body.clientHeight / 2 - img.clientHeight / 2}px`;
    });
  },
  methods: {
    getPos(event) {
      let x = 0;
      let y = 0;
      if (event.touches !== undefined) {
        x = event.touches[0].clientX;
        y = event.touches[0].clientY;
      } else {
        x = event.clientX;
        y = event.clientY;
      }
      return { x: x, y: y };
    },
    startHandler(event) {
      const bound = this.$refs.img.getBoundingClientRect();
      this.startPos = this.getPos(event);
      this.startPos.x -= bound.left;
      this.startPos.y -= bound.top;
      this.isDrag = true;
    },
    moveHandler(event) {
      if (!this.isDrag) return;
      const pos = this.getPos(event);
      this.$refs.img.style.left = `${pos.x - this.startPos.x}px`;
      this.$refs.img.style.top = `${pos.y - this.startPos.y + 270}px`;
    },
    endHandler(event) {
      this.isDrag = false;
    },
    resizeImage() {
      const body = document.querySelector("body");
      const w = body.offsetWidth;
      const h = body.offsetHeight;
    },
    moveImage(event) {
      const ele = event.target;
    },
    doClose() {
      this.$emit("close");
    },
  },
};
</script> 
<style lang="scss" scoped>
.img-viewer {
  width: 200%;
  height: 200%;
  border: 2px solid #000;
  position: absolute;
  top: -16%;
  right: 0;
  bottom: 0;
  left: -68%;
  background-color: rgba( 0, 0, 0, 0.7 );
  .origin {
    position: absolute;
    -ms-user-select: none;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    opacity: 1;
    width : 630px;
    height: 891px;
  }
  img.origin {
    position: absolute;
    -ms-user-select: none;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    margin-top : -270px;
  }
  img.btn-close {
    display: block;
    width: 40px;
    height: 40px;
    border: 2px solid #000;
    background-color: #fff;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}
</style>
