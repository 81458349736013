<template>
  <v-app id="inspire">
    <div class="app-context">
      <v-app-bar
        absolute
        color="#fcb69f"
        dark
        shrink-on-scroll
        src="https://picsum.photos/1920/1080?random"
      >
        <template v-slot:img="{ props }">
          <v-img
            v-bind="props"
            gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
          ></v-img>
        </template>

        <div class="logo" style="margin : auto; width : 1000px">
          <v-row>
            <v-col cols="12" md="2"
              ><img class="header_logo" src="@/assets/images/logo_default.svg"
            /></v-col>
            <v-col cols="12" md="8"
              ><h1
                class="couponTitle"
                style="font-size : 32px; position : relative; top : 3px; margin-left : 20px"
              >
                쿠폰 메일 발송
              </h1></v-col
            >
          </v-row>
        </div>

        <v-spacer></v-spacer>
      </v-app-bar>
      <div style="margin : 150px 20px 0 20px">
        <div style="position: relative; width: 80%; left: 8%">
          <div
            style="width: 100%; text-align: center; font-size: 32px; margin: 10px"
          >
            <span class="couponTitle">쿠폰 메일 발송</span>
          </div>
          <v-container>
            <div class="row">
              <div>
                <v-text-field
                  height="60"
                  class="cponId"
                  id="readonly1"
                  label="쿠폰번호(8자 이내)"
                  :rules="rules"
                  hide-details="auto"
                  style="width : 700px; font-size : 20px;"
                  :value="this.couponParam.cponId"
                  readonly="readonly"
                ></v-text-field>
              </div>
            </div>
            <div class="row">
              <div class="diag_label" style="margin-top : 40px;">
                <h3 class="title">전체 회원리스트</h3>
              </div>
            </div>
            <div style="height : 420px; width : 100%;">
              <v-row>
                <v-col cols="12" md="2" style="min-width : 170px; height : 40px; border-bottom : 2px solid black;">
                  <span>아이디</span>
                </v-col>
                <v-col cols="12" md="2" style="min-width : 250px; height : 40px; border-bottom : 2px solid black;">
                  <span>병원명</span>
                </v-col>
                <v-col cols="12" md="2" style="min-width : 200px; height : 40px; border-bottom : 2px solid black;">
                  <span>의사명</span>
                </v-col>
                <v-col cols="12" md="2" style="width : 150px; height : 40px; border-bottom : 2px solid black;">
                  <span>E-Mail</span>
                </v-col>
              </v-row>
              <!-- <v-divider></v-divider> -->
              <v-row v-for="(listItem, index) in list" :key="index + 'A'" class="listRow" @click="addSendList(listItem)">
                <v-col cols="12" md="2" style="min-width : 170px; height : 40px; border-bottom : 1px solid gray;"  >
                  <span>{{ listItem.drId }}</span>
                </v-col>
                <v-col cols="12" md="2" style="min-width : 250px; height : 40px; border-bottom : 1px solid gray;">
                  <span>{{ listItem.hospNm }}</span>
                </v-col>
                <v-col cols="12" md="2" style="min-width : 200px; height : 40px; border-bottom : 1px solid gray;">
                  <span>{{ listItem.drNm }}</span>
                </v-col>
                <v-col cols="12" md="2" style="width : 150px; height : 40px; border-bottom : 1px solid gray;">
                  <span>{{ listItem.email }}</span>
                </v-col>
              </v-row>
            </div>
            <div style="height : 50px" />
            <div class="pagination-wrap" style="left : 0px;">
              <v-row>
                <v-col cols="12" md="2" style="width : 10px"></v-col>
                <v-col cols="12" md="1" style="width : 10px; cursor : pointer">
                  <p
                    class="navigation-act prev"
                    v-if="listStatusBtn.first > 1"
                    @click="navigatorAct('prev')"
                  >
                    <v-icon
                      large
                      color="teal darken-2"
                      style="left : 50px; top : 0px; z-index : 1"
                    >
                      mdi-chevron-left
                    </v-icon>
                  </p>
                </v-col>
                <v-col cols="12" md="4" style="cursor : pointer">
                  <ul
                    id="pagination1"
                    style="display : flex; text-align : center"
                  >
                    <li
                      id="btnPaginationId" 
                      class="btnPagination"
                      v-for="(pagination,
                      index) in listStatusBtn.currentPaginationList"
                      :key="index + 'j'"
                      @click="loadList(pagination, index)"
                      style="width : 40px"
                    >
                      <p style="top: 5px; position : relative">
                        {{ pagination }}
                      </p>
                    </li>
                  </ul>
                </v-col>

                <v-col cols="12" md="1" style="width : 10px; cursor : pointer">
                  <p
                    class="navigation-act next"
                    v-if="
                      listStatusBtn.next > 5 &&
                        listStatusBtn.last < listStatusBtn.totalPage
                    "
                    @click="navigatorAct('next')"
                  >
                    <v-icon
                      large
                      color="teal darken-2"
                      style="left : -50px; top : 0px"
                    >
                      mdi-chevron-right
                    </v-icon>
                  </p>
                </v-col>
                <v-col cols="12" md="2" style="width : 10px"></v-col>
              </v-row>
            </div>
            <div class="row">
              <div class="diag_label" style="margin-top : 40px;">
                <h3 class="title">발송리스트</h3>
              </div>
            </div>
            <div class="row">
              <div class="btn_box scroll type1">
                <div class="btn_list">
                  <span
                    class="btn_style"
                    v-for="(data, index) in sendDataList"
                    :key="index + 'a'"
                  >
                    <v-btn class="btn_select" rounded color="primary">
                      <v-icon
                        dense
                        style="cursor: pointer; 
                        vertical-align : top;
                        margin-right : 5px;"
                        color="white"
                        @click="deleteSelection(data.email)"
                        >mdi-close-circle</v-icon
                      >
                      {{ data.hospNm }}
                      <span>({{ data.email }})</span>
                    </v-btn>
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="row add-btn" style="margin-top : 40px;">
                <div
                  class="modifyBtnApply"
                  style="width: 100%; text-align: center; font-size: 32px;"
                >
                  <v-btn
                    class="white--text"
                    outlined
                    tile
                    dense
                    large
                    style="margin: 0 0px 0 0px; width: 350px; height: 70px; background-color: #146f83;"
                    @click="callHospitalInfo()"
                    ><v-icon size="35" style="margin-right: 15px;"
                      >mdi-account-plus</v-icon
                    >
                    <span
                      class="modifyBtnText"
                      style="font-size : 35px; line-height : 10px;"
                      >회원검색</span
                    >
                  </v-btn>
                </div>
              </div>
              <div class="row add-btn" style="margin-top : 40px;">
                <div
                  class="modifyBtnApply"
                  style="width: 100%; text-align: center; font-size: 32px;"
                >
                  <v-btn
                    class="primary white--text"
                    outlined
                    tile
                    dense
                    large
                    style="margin: 0 0px 0 0px; width: 350px; height: 70px;"
                    @click="sendMail()"
                    ><v-icon size="35" style="margin-right: 15px;"
                      >mdi-email-send-outline</v-icon
                    >
                    <span
                      class="modifyBtnText"
                      style="font-size : 35px; line-height : 10px;"
                      >쿠폰 발송하기</span
                    >
                  </v-btn>
                </div>
              </div>
            </div>
          </v-container>
          <div style="height: 100px" />
        </div>
      </div>
      <default-popup v-if="checkPopup" :popupSet="popupSet" />
      <search-hospital-popup
        v-if="checkSearchPopup"
        :popupSetSearch="popupSetSearch"
        @receive-doctor="receiveDoctor"
        @reload-nav="reloadNav"
      />
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import ImageViewer from "@/components/ImageViewer";
import DefaultPopup from "@/components/modal/DefaultPopup";
import AdminPopup from "@/components/modal/AdminPopup";
import SearchHospitalPopup from "@/components/modal/SearchHospitalPopup";
export default {
  components: { ImageViewer, DefaultPopup, SearchHospitalPopup, AdminPopup },
  data() {
    return {
      list: [],
      popupSet: {},
      popupSetSearch: {},
      photo: "",
      couponParam: {
        cponId: "",
        publDt: this.$moment().format("YYYYMMDD"),
        prodDvcd: "1",
        cponNm: "",
        cponAmt: "-60000",
        validStrDt: this.$moment().format("YYYYMMDD"),
        validEndDt: this.$moment()
          .add()
          .format("YYYYMMDD"),
        useYn: "Y",
        creId: "",
        updId: "",
        creDt: "",
        updDt: "",
        regiId: "",
        drId: "",
        regDt: "",
        useDt: "",
      },
      rules: [
        (value) => !!value || "최소 2자 이상 입력해주세요.",
        (value) => (value && value.length >= 2) || "최소 2자 이상 입력해주세요",
      ],
      listStatus: {
        totalCount: null, // 조회하여 가져온 총 데이터의 수
        dataOnPage: 10, // 한 페이지에 내려줄 데이터의 수
        pageCount: 5, // 한 페이지에 1~5까지 보여준다.
        totalPage: null, // 페이지네이션에 출력한 총 페이지의 수
        currentPage: 1, // 현재 페이지
        prev: null,
        next: null,
        first: null,
        last: null,
        currentPaginationList: [],
        /**
         * totalCount: 22
         * currentPage: 1
         * totalPage: 3
         * pageGroup: 1
         * last: 3
         * first: -1
         * next: 4
         * prev: -2
         */
      },
      listStatusBtn: {
        totalCount: null, // 조회하여 가져온 총 데이터의 수
        dataOnPage: 10, // 한 페이지에 내려줄 데이터의 수
        pageCount: 5, // 한 페이지에 1~5까지 보여준다.
        totalPage: null, // 페이지네이션에 출력한 총 페이지의 수
        currentPage: 1, // 현재 페이지
        prev: null,
        next: null,
        first: null,
        last: null,
        currentPaginationList: [],
        /**
         * totalCount: 22
         * currentPage: 1
         * totalPage: 3
         * pageGroup: 1
         * last: 3
         * first: -1
         * next: 4
         * prev: -2
         */
      },
      dataParam: "",
      sendDataList: [],
    };
  },
  props: {
    apiSet: {
      title: {
        type: String,
      },
      content: {
        type: String,
      },
      placeholder: {
        type: String,
      },
      apiBtnText: {
        type: String,
      },
      warnMessage: {
        type: String,
      },
      confirmBtnText: {
        type: String,
      },
      cancelBtnText: {
        type: String,
      },
      popType: {
        type: String,
      },
      nextLink: {
        type: String,
      },
      prevLink: {
        type: String,
      },
      defineUrl: {
        type: String,
      },
      customParam: {
        type: String,
      },
      customParam2: {
        type: String,
      },
      dataParam: {
        type: Object,
      },
      isFixing: {
        type: Boolean,
      },
      rcvAttachment: {
        type: Object,
      },
    },
  },
  created() {
    this.getCoupon();
    this.config();
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
  mounted(){
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
   var a = document.querySelector('#pagination1');

  },
  computed: {
    ...mapState("basic", ["checkPopup", "checkSearchPopup"]),
    ...mapGetters("join", ["GET_BASIC"]),
    ...mapGetters("promotion", ["GET_PROMOTION"]),
    ...mapGetters("admin", [
      "GET_PROD_DATA",
      "GET_PROD_LIST",
      "GET_CPONID_DATA",
      "GET_COUPON_DATA",
      "GET_PRGS_LIST",
    ]),
  },
  methods: {
    ...mapMutations("basic", [
      "SET_POPUP",
      "SET_CHILD_LOADER",
      "SET_SEARCH_POPUP",
    ]),
    ...mapActions("join", ["FETCH_BASIC_TARGET_UN_AUTH"]),
    ...mapActions("promotion", ["FETCH_PROMOTION_TARGET_UN_AUTH"]),
    ...mapActions("admin", [
      "BRING_COUPON_DATA",
      "UPDATE_COUPON_DATA",
      "BRING_PROD_DATA",
      "BRING_PROD_LIST",
      "BRING_CPONID_DATA",
      "ADD_COUPON_DATA",
      "SEND_COUPON_EMAIL",
      "BRING_USE_Y_LIST",
    ]),
    viewPhoto(src) {
      this.photo = src;
      this.showImage = true;
    },
    initListStatus() {
      this.listStatus.totalCount = null;
      this.listStatus.dataOnPage = 10;
      this.listStatus.pageCount = 5;
      this.listStatus.totalPage = null;
      this.listStatus.currentPage = 1;
      this.listStatus.prev = null;
      this.listStatus.next = null;
      this.listStatus.first = null;
      this.listStatus.last = null;
      this.currentPaginationList = [];
      this.listStatusBtn.totalCount = null;
      this.listStatusBtn.dataOnPage = 10;
      this.listStatusBtn.pageCount = 5;
      this.listStatusBtn.totalPage = null;
      this.listStatusBtn.currentPage = 1;
      this.listStatusBtn.prev = null;
      this.listStatusBtn.next = null;
      this.listStatusBtn.first = null;
      this.listStatusBtn.last = null;
      this.currentPaginationList = [];
    },
    async config() {
      this.currentPage = 0; // 현재 페이지
      this.initListStatus();
      this.list = [];
      this.SET_CHILD_LOADER(true);

      const searchOpt = {
        pageNo: 1,
        recordsPerPage: this.listStatusBtn.dataOnPage,
      };
      try {
          await this.BRING_USE_Y_LIST(searchOpt).then(() => {
            this.listStatusBtn.totalCount = this.GET_PRGS_LIST.param.totalCount;
            this.listStatusBtn.currentPage = this.GET_PRGS_LIST.param.pageNo;
            var list = JSON.parse(JSON.stringify(this.GET_PRGS_LIST.list));
            setTimeout(() => {
              this.SET_CHILD_LOADER(false);
              this.list = list;
              this.preParePagination();
            }, 800);
          });
      } catch (error) {
        console.log("erorr", error);
      }
    },
    async getCoupon() {
      if(this.$route.params.code === null 
        || this.$route.params.code === undefined)
        this.$route.params.code = "";

        var cponIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{8,14}$/;

        if(!cponIdBoo.test(this.$route.params.code)){
          this.SET_POPUP(true);
          this.popupSet.title = "존재하지 않는 쿠폰번호";
          this.popupSet.content = "존재하지 않는 쿠폰번호입니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

      await this.BRING_COUPON_DATA(this.$route.params.code).then(() => {
        this.couponParam = this.GET_COUPON_DATA;
      });
    },
    callHospitalInfo() {
      document.querySelector("body").classList.add("spreaded");
      this.showNav = false;
      this.SET_SEARCH_POPUP(true);
    },
    addSendList(listItem){
      var valid = true;
      for (let i = 0; i < this.sendDataList.length; i++) {
        if (this.sendDataList[i].email === listItem.email) {
          valid = false;
        }
      }
      if (valid === true) this.sendDataList.push(listItem);
    },
    receiveDoctor(doctorInfo) {
      var valid = true;
      for (let i = 0; i < this.sendDataList.length; i++) {
        if (this.sendDataList[i].email === doctorInfo.email) {
          valid = false;
        }
      }
      if (valid === true) this.sendDataList.push(doctorInfo);
    },
    reloadNav() {
      if (!this.showNav) {
        this.showNav = true;
      }
    },
    deleteSelection(email) {
      for (let i = 0; i < this.sendDataList.length; i++) {
        if (this.sendDataList[i].email === email) {
          this.sendDataList.splice(i, 1);
          i--;
        }
      }
    },
    async sendMail() {
      if (this.sendDataList.length === 0) {
        this.SET_POPUP(true);
        this.popupSet.title = "발송 대상 없음";
        this.popupSet.content =
          "쿠폰을 발송할 리스트가 없습니다. 내용을 확인해주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      } else {
        //유효성 검사

        //1. 쿠폰번호
        if(this.couponParam.cponId === null 
        || this.couponParam.cponId === undefined)
        this.couponParam.cponId = "";

        var cponIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{8,14}$/;

        if(!cponIdBoo.test(this.couponParam.cponId)){
          this.SET_POPUP(true);
          this.popupSet.title = "존재하지 않는 쿠폰번호";
          this.popupSet.content = "존재하지 않는 쿠폰번호입니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        //2. 쿠폰 유효기간 시작일자
        if(this.couponParam.validStrDt === null 
        || this.couponParam.validStrDt === undefined)
        this.couponParam.validStrDt = "";

        var validStrDtdBoo = /^[0-9]{8,8}$/;

        if(!validStrDtdBoo.test(this.couponParam.validStrDt)){
          this.SET_POPUP(true);
          this.popupSet.title = "쿠폰 유효기간 확인";
          this.popupSet.content = "유효기간이 정상적으로 작성되지 않았습니다. 유효기간을 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        //3. 쿠폰 유효기간 종료일자
        if(this.couponParam.validEndDt === null 
        || this.couponParam.validEndDt === undefined)
        this.couponParam.validEndDt = "";

        var validEndDtBoo = /^[0-9]{8,8}$/;

        if(!validEndDtBoo.test(this.couponParam.validEndDt)){
          this.SET_POPUP(true);
          this.popupSet.title = "쿠폰 유효기간 확인";
          this.popupSet.content = "유효기간이 정상적으로 작성되지 않았습니다. 유효기간을 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }
        
        var couponParam = {
          cponId: "",
          validStrDt: "",
          validEndDt: "",
          email: [],
        };
        couponParam.cponId = this.couponParam.cponId;
        couponParam.validStrDt = this.couponParam.validStrDt;
        couponParam.validEndDt = this.couponParam.validEndDt;

        //4. 이메일
        var emailValidate = false;

        for (let i = 0; i < this.sendDataList.length; i++) {
          let email = this.sendDataList[i].email;
          
          if(email === null 
            || email === undefined)
            email = "";

          var emailBoo = /^[a-z0-9A-Z._-]*@[a-z0-9A-Z]*.[a-zA-Z.]*$/

          if(!emailBoo.test(email)){
            emailValidate = true;
            return;
          }

          couponParam.email.push(email);
        }

        if(emailValidate){
          this.SET_POPUP(true);
            this.popupSet.title = "비정상적 이메일 확인";
            this.popupSet.content = "비정상적인 이메일을 확인하였습니다. \n 이메일을 확인하신 후 재요청 부탁드립니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
        }

        const transData = new FormData();
        const transedCouponParam = JSON.stringify(couponParam);
        transData.append("couponParam", transedCouponParam);
        try {
          this.SET_POPUP(true);
          this.popupSet.title = "메일 전송 중";
          this.popupSet.content = " 메일 전송 중입니다. 잠시만 기다려주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          await this.SEND_COUPON_EMAIL(transData).then(() => {
            setTimeout(() => {
              $('.btn-wrap button').click();
            }, 10);
            setTimeout(() => {
              this.SET_POPUP(true);
              this.popupSet.title = "쿠폰 전송 완료";
              this.popupSet.content = "쿠폰 전송이 완료되었습니다.";
              this.popupSet.popType = "refund";
              this.popupSet.confirmBtnText = undefined;
              this.popupSet.cancelBtnText = "확인";
            }, 500);
            
          });
        } catch (error) {
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.SET_POPUP(true);
            this.popupSet.title = "쿠폰 전송 실패";
            this.popupSet.content =
              "쿠폰을 전송하는데 실패하였습니다. 내용을 확인해주세요.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        }
      }
    },
    initPaginationEffect() {
      for (
        let k = 0;
        k < document.querySelector("#pagination1").childElementCount;
        k++
      ) {

        if (
          document
            .querySelector("#pagination1")
            .childNodes[k].classList.contains("active")
        ) {
          document
            .querySelector("#pagination1")
            .childNodes[k].classList.remove("active");
        }
      }
    },
    async loadList(currentPage, order) {
      this.list = [];
      this.SET_CHILD_LOADER(true);
      this.initPaginationEffect();
      /*document
        .querySelector("#pagination")
        .childNodes[0].classList.add("active");*/
      /*if (this.listStatus.totalCount >= 1) {
        if (order > this.listStatus.last) {
          order = this.listStatus.last - 1;
        }
        document
          .querySelector("#pagination")
          .childNodes[order].classList.add("active");
      }*/
      const searchOpt = {
        pageNo: currentPage,
        recordsPerPage: this.listStatus.dataOnPage,
      };
      try {
          await this.BRING_USE_Y_LIST(searchOpt).then(() => {
            this.listStatusBtn.totalCount = this.GET_PRGS_LIST.param.totalCount;
            this.listStatusBtn.totalPage = this.GET_PRGS_LIST.param.totalPage;
            this.listStatusBtn.first = this.GET_PRGS_LIST.param.first;
            this.listStatusBtn.last = this.GET_PRGS_LIST.param.last;
            this.listStatusBtn.prev = this.GET_PRGS_LIST.param.prev;
            this.listStatusBtn.next = this.GET_PRGS_LIST.param.next;
            this.listStatusBtn.currentPage = this.GET_PRGS_LIST.param.pageNo;
            let tempList = JSON.parse(JSON.stringify(this.GET_PRGS_LIST.list));
            setTimeout(() => {
              this.SET_CHILD_LOADER(false);
              this.list = tempList;
              this.preParePagination();
            }, 800);
          });
      } catch (error) {
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = " 리스트를 불러올 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    preParePagination() {
      /**
      this.listStatus.totalCount = 122;
       */
      /** api로 list를 불러온 과정 생략 */
      const totalCount = this.listStatusBtn.totalCount;
      const currentPage = this.listStatusBtn.currentPage;
      const dataOnPage = this.listStatusBtn.dataOnPage;
      const pageCount = this.listStatusBtn.pageCount;
      const totalPage = Math.ceil(totalCount / dataOnPage);
      const pageGroup = Math.ceil(currentPage / pageCount);
      let last = pageGroup * pageCount;
      if (last > totalPage) {
        last = totalPage;
      }
      let first = last - (pageCount - 1);
      const next = last + 1;
      const prev = first - 1;
      if (totalPage < 1) {
        first = last;
      }
      this.listStatusBtn.totalPage = totalPage;
      this.listStatusBtn.prev = prev;
      this.listStatusBtn.next = next;
      this.listStatusBtn.first = first;
      this.listStatusBtn.last = last;
      this.calculatedPaginationList(first, last);
      /**
       * totalCount: 22
       * currentPage: 1
       * totalPage: 3
       * pageGroup: 1
       * last: 3
       * first: -1
       * next: 4
       * prev: -2
       */
      /** [next]
       *  first -> pageGroup  * dataOnPage + 1
       *  last  -> ((pageGroup + 1) * dataOnPage) - 1
       *  [prev]
       *  first -> ((pageGroup - 2) * dataOnPage) + 1
       *  last  -> ((pageGroup - 1) * dataOnpage) - 1
       *
       */
    },
    calculatedPaginationList(first, last, type) {
      const paginationListArray = [];
      for (let j = first; j <= last; j++) {
        if (j > 0) {
          paginationListArray.push(j);
        }
      }
      for (let i = 0; i <= 5; i++) {
        if (last % 5 !== 0) {
          if (paginationListArray[0] % 5 !== 1) {
            paginationListArray.shift();
          } else {
            this.listStatusBtn.first = paginationListArray[0];
            break;
          }
        }
      }

      this.listStatusBtn.currentPaginationList = paginationListArray;
      if (type === "prev") {
        this.$nextTick((_) => {
          if (this.listStatusBtn.last % 5 !== 0) {
            let num = this.listStatusBtn.last % 5;
            this.listStatusBtn.last = this.listStatusBtn.last + (5 - num);
            this.loadList(this.listStatusBtn.last, 4);
          } else {
            this.loadList(this.listStatusBtn.last, 4);
          }
          for (
            let k = 0;
            k < document.querySelector("#pagination1").childElementCount;
            k++
          ) {
            if (
              Number(
                document.querySelector("#pagination1").childNodes[k]
                  .childNodes[0].textContent
              ) === Number(this.listStatusBtn.last)
            ) {
              document
                .querySelector("#pagination1")
                .childNodes[k].classList.add("active");
            }
          }
        });
      } else if (type === "next") {
        this.loadList(this.listStatusBtn.currentPage, 0);
        document
          .querySelector("#pagination1")
          .childNodes[0].classList.add("active");
      } else {
        if (this.list.length > 0) {
          this.$nextTick((_) => {
            const target = document.querySelectorAll(".tab-wrapper")[
              this.currentTab
            ];
            if (target !== undefined) {
              target
                .querySelector("#pagination1")
                .childNodes[this.listStatusBtn.currentPage - 1].classList.add(
                  "active"
                );
            }
            if (this.listStatusBtn.currentPage > 5) {
              if (this.listStatusBtn.currentPage % 5 === 0) {
                document
                  .querySelector("#pagination1")
                  .childNodes[4].classList.add("active");
              } else {
                document
                  .querySelector("#pagination1")
                  .childNodes[
                    (this.listStatusBtn.currentPage % 5) - 1
                  ].classList.add("active");
              }
            } else {
              document
                .querySelector("#pagination1")
                .childNodes[this.listStatusBtn.currentPage - 1].classList.add(
                  "active"
                );
            }
            //강제 active 삽입
          });
        }
      }
    },
    navigatorAct(type) {
      let first = this.listStatusBtn.first;
      let last = this.listStatusBtn.last;
      const totalPage = this.listStatusBtn.totalPage;
      const paginationListArray = [];
      let next = this.listStatusBtn.next;
      let prev = this.listStatusBtn.prev;
      for (let j = first; j <= last; j++) {
        if (j > 0) {
          paginationListArray.push(j);
        }
      }
      this.listStatusBtn.currentPaginationList = paginationListArray;
      this.initPaginationEffect();
      /**
       * first next last
       * 보정 가정
       * */
      if (type === "prev") {
        last = first - 1;
        next = last + 1;
        first = first - 5;
        prev = first - 1;
        /**
         * 11    15
         * 6     10
         * currentPage = first
         * totalCount = 13
         */
        this.listStatusBtn.currentPage = last;
      } else if (type === "next") {
        first = first + 5;
        prev = first - 1;
        if (totalPage > last + 5) {
          last = last + 5;
          next = last + 1;
        } else {
          last = totalPage;
          next = last;
        }
        /**
         * 6    10
         * 11   15
         * currentPage = first
         * totalCount = 13
         */
        this.listStatusBtn.currentPage = first;
      }

      this.listStatusBtn.first = first;
      this.listStatusBtn.last = last;
      this.listStatusBtn.prev = prev;
      this.listStatusBtn.next = next;
      this.calculatedPaginationList(first, last, type);
    },
  },
  destroyed() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "on",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/admin";
.img-viewer {
  top: -200px !important;
}
.origin {
  position: relative !important;
  top: 340px !important;
  left: 90px !important;
}

h3.title {
  color: #146f83;
  display: inline-block;
  font-weight: 900 !important;
}
.wrap {
  margin: 20px;
  min-height: 120px;
  width: 1070px;
  display: block;
  clear: both;
}
.label {
  float: "left";
}

.btn_style {
  color: #ffffff;
  margin-top: 10px;
  margin-left: 9px;
  float: left;
}
.scroll {
  padding: 0px 13px 10px 13px !important;
  overflow-y: scroll;
  box-sizing: border-box;
  color: #146f83;
  font-family: "Nanum Gothic";
  margin-right: 50px;
}

/* 스크롤바 설정*/
.type1::-webkit-scrollbar {
  width: 12px;
}

/* 스크롤바 막대 설정*/
.type1::-webkit-scrollbar-thumb {
  height: 17%;
  background-color: #146f83;
  /* 스크롤바 둥글게 설정    */
  border-radius: 10px;
}

.btn_box {
  clear: both;
  min-width: 980px;
  height: 300px;
  border: 2px solid #146f83;
  margin-bottom: 30px;
  position: relative !important;
  left: 10px;
}
.btn_select {
  text-transform: none;
}
/* 액티브 문제 => document.쿼리셀렉터로 처리하기 */
#btnPaginationId {
  &.active {
    border-radius: 3px;
    background-color: #146f83;
    p {
      color: #fff;
    }
  }
}
.listRow:hover{
  background-color: rgb(197, 170, 129);
}
</style>
